import { GetServerSideProps, NextPage } from "next";
import dynamic from "next/dynamic";
import React from "react";

import { withSession } from "@/components/session";
import StandardLayout from "@/components/standard-layout";

import { withSessionSsr } from "../utils/auth";
import { withPageTranslations } from "../utils/with-page-translations";


const ListPage = dynamic(() => import("@/components/list"), { ssr: false });

const PagelistLoader: NextPage = () => {

    return (
      <StandardLayout>
          <ListPage />
      </StandardLayout>
    );

};

export const getServerSideProps: GetServerSideProps = withSessionSsr(
  withPageTranslations(["common", "app"]),
);

export default withSession(PagelistLoader);
